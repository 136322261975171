import gsap from "gsap";
import splitting from "splitting";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";



export default class Snake {
  constructor(state) {
    this._state = state;

    if ($('.js-snakePath')[0]) {
      gsap.registerPlugin(MotionPathPlugin);
      Splitting();
      this._init();
    }
  }



  _init() {
    var tl1 = new gsap.timeline();
    var line1 = $('.js-snakePath');
    gsap.set(line1, {
      y: 18,
      x: 40
    });
    gsap.set(".snake_text .word .char", {
      x: -9999
    });
    tl1.to('.snake_text .word .char, .snake_text .whitespace', {
      motionPath: {
        path: '.js-snakePath',
        align: '.js-snakePath',
        alignOrigin: [0.5, 1],
        autoRotate: true
      },
      stagger: {
        each: 0.075,
        repeat: -1
      },
      duration: 15,
      runBackwards: true,
      ease: "none"
    });
  }



}
