export default class Core {
  constructor(state) {
    this._state = state;
    this._loading();
  }



  _loading() {
    const _timer = setTimeout(function () {
      $('body').attr('data-state', 'loaded');
      clearTimeout(_timer);
    }, 500);
  }



}
