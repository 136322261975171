import gsap from "gsap";
import 'slick-carousel';



export default class HomeHeroSlider {
  constructor(state) {
    this._state = state;
    this.$slider = $('.js-homeHeroSlider');
    this._current = 0;
    this._total = $('> div', this.$slider).length;
    this._activeGauge;

    this._slider();
  }



  _slider() {
    const _draggable = this._state.DEVICE ? true : false;
    const _this = this;

    this.$gauge = this.$slider.siblings('.homeHeroGauge');

    this.$slider.on('init', function(slick){
      _this._gauge();

      const _timer = setTimeout(function () {
        $('.slick-slide', _this.$slider).eq(0).addClass('inView');
        clearTimeout(_timer);
      }, 400);
    });

    this.$slider.on('afterChange', function(event, slick, currentSlide){
      // console.log("afterChange / " + currentSlide);
      _this._lock();
    });

    this.$slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      // console.log("beforeChange / " + currentSlide+" / "+nextSlide);
      _this._lock();

      $('.slick-slide', _this.$slider).eq(currentSlide).removeClass('inView');
      $('.slick-slide', _this.$slider).eq(nextSlide).addClass('inView');
    });

    this.$slider.on('swipe', function(event, slick, dir){
      _this._swipe(dir);
    });

    this.$slider.slick({
      pauseOnFocus: false,
      pauseOnHover: false,
      focusOnSelect: false,
      pauseOnDotsHover: false,
      infinite: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      speed: 800,
      fade: true,
      draggable: false,
      arrows: false,
    });
  }



  _gauge() {
    const _this = this;
    const $list = $('.homeHeroGauge_list', this.$gauge);

    for (var i = 0; i < this._total; i++) {
      $list.append('<div><i></i></div>');
    }

    this._start();

    $('> div', $list).on(this._state.TOUCH.CLICK, (e) => {
      const _num = $('> div', $list).index($(e.currentTarget));

      $('> div.is-active', $list).removeClass('is-active');
      gsap.killTweensOf($('> i', _this._activeGauge)[0]);
      _this._change(_num, 'gauge');
    });
  }



  _start() {
    const _this = this;

    const _timer = setTimeout(function () {
      _this._progress(true);
      clearTimeout(_timer);
    }, 100);
  }



  _progress(_flagFirst) {
    const $progress = $('.homeHeroGauge_list > div', this.$gauge).eq(this._current);
    let _duration = 6.5;
        _duration = _flagFirst ? _duration : _duration + 2;
    const _this = this;

    this._activeGauge = $progress;
    this._activeGauge.addClass('is-active');

    gsap.fromTo($('> i', $progress)[0], {
      width: 0,
    }, {
      width: '100%',
      duration: _duration,
      easing: 'linear',
      onComplete: function() {
        _this._change(null, 'progress');
      }}
    );
  }



  _change(_next, _from) {
    if (_next == null) {
      if ((this._current >= this._total - 1)) {
        this._current = 0;
      }
      else {
        this._current = this._current + 1
      }
    }
    else {
      this._current = _next;
    }

    if (_from != 'swipe') {
      this.$slider.slick('slickGoTo', this._current);
    }

    this._outGauge(this._activeGauge);
    this._progress(false);
  }



  _outGauge($gauge) {
    const $bar = $gauge.find('i');

    $gauge.removeClass('is-active');

    gsap.to($bar, {
      opacity: 0,
      duration: .5,
      easing: 'linear',
      onComplete: function() {
        $bar.css({
          width: 0,
          opacity: 1
        });
      }}
    );
  }



  _swipe(_dir) {
    const $list = $('.homeHeroGauge_list', this.$gauge);

    $('> div.is-active', $list).removeClass('is-active');
    gsap.killTweensOf($('> i', this._activeGauge)[0]);

    let _result = (_dir == 'left') ? (this._current + 1) : (this._current - 1);

    if (_result < 0) {
      _result = this._total - 1;
    }

    if (_result >= this._total) {
      _result = 0;
    }

    this._change(_result, 'swipe');
  }



  _lock() {
    const $parent = this.$slider.parent();

    $parent.hasClass('is-lock') ? $parent.removeClass('is-lock') : $parent.addClass('is-lock');
  }



}
