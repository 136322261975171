import gsap from "gsap";
import { TweenMax, Power2, ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin);

export default class Anchor {
  constructor(state, mediaQuery) {
    this._state = state;

    this._$html = $('html');
    this._$body = $('body');
    this._scrollTo();
  }

  _scrollTo() {
    $('body').on('click', 'a[rel^="#"], a[rel^="."]', (e) => {
      let target = $($(e.currentTarget).attr('rel'));
      let top = target.offset().top ? target.offset().top - 60 : 0;
      
      if (this._$body.hasClass('customize-support')) {
        top -= 32;
      }

      this._$html.addClass('is-scrolling');

      TweenMax
      .to(window, 1, {scrollTo: {y: top, x: 0, autoKill: false}, ease: Power2.easeInOut})
      .eventCallback('onComplete', () => {
        this._$html.removeClass('is-scrolling');
      })

      return false;
    });
  }
}
