// const fancybox = require("jquery-fancybox")($);
import '@fancyapps/fancybox';



export default class MovieModal {
  constructor(state) {
    this._state = state;
    this._modal();
  }



  _modal() {
    const _width = this._state.IS_MOBILE ? '95%' : '80%';

    $('.js-movieModal').fancybox({
      width: _width,
      type: 'iframe',
      padding: 0,
      openEffect: 'swing',
      openSpeed: 250,
      closeEffect: 'swing',
      closeSpeed: 150,
      closeClick: true,
      closeBtn: true,
      animationEffect: 'fade',
      helpers: {
        media: true
      },
      youtube : {
        controls : 0,
        showinfo : 0,
        autoplay: 0
      }
    });
  }



}
