export default class Share {
  constructor(state) {
    this._state = state;

    this._popup();
  }

  _popup() {
    $('.js-sharePopup').on(this._state.TOUCH.CLICK, (e) => {
      const _sns = e.currentTarget.dataset.sns;
      const _text = encodeURI(e.currentTarget.dataset.text ? e.currentTarget.dataset.text : document.title);
      const _url = encodeURI(e.currentTarget.dataset.url ? e.currentTarget.dataset.url : location.href);
      const _w = (screen.width - 640) / 2;
      const _h = (screen.height - 480) / 2;
      let _href;

      if (_sns === 'twitter') {
        _href = 'https://twitter.com/intent/tweet?url=' + _url + '&text=' + _text;
      }
      else if (_sns === 'facebook') {
        _href = 'https://www.facebook.com/sharer/sharer.php?u=' + _url;
      }
      else if (_sns === 'line') {
        _href = 'http://line.me/R/msg/text/?' + _url;
      }
      else if (_sns === 'mastodon') {
        _href = 'https://pawoo.net/intent/statuses/new?text=' + _text + ' ' + _url;
      }
      else if (_sns === 'google') {
        _href = 'https://plus.google.com/share?url=' + _url;
      }
      else if (_sns === 'hatena') {
        _href = `https://b.hatena.ne.jp/add?mode=confirm&url=${_url}&title=${_text}`;
      }

      window.open(_href, 'window' ,'width=640,height=480'+',left=' + _w + ', top='+ _h);

      return false;
    });
  }


}
