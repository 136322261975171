export default class ItemGallery {
  constructor(state) {
    this._state = state;

    const $gallery = $('.js-itemGalley');
    const _this = this;

    if ($gallery[0]) {
      $gallery.on('init', function(event, slick) {
        // _this._zoom();
        $gallery.addClass('is-loaded');
        $('.loader').remove();
      });

      $gallery.slick({
        dots: true,
        fade: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        pauseOnFocus: false,
        pauseOnHover: false,
        focusOnSelect: false,
        prevArrow: "<a href='#' class='slick-prev'><i></i></a>",
        nextArrow: "<a href='#' class='slick-next'><i></i></a>",
        customPaging: function(slick, index) {
          var targetImage = slick.$slides.eq(index).find('img').attr('src');
  	      return '<div style="background-image: url(' + targetImage + ');"></div>';
        }
      });
    }
  }



  _zoom() {
    // $('.itemGallery_item').zoom();
  }



}
