import Swiper from 'swiper/bundle';
import "swiper/swiper-bundle.css"



export default class ItemCarousel {
  constructor(state) {
    this._state = state;
    this._init();
  }



  _init() {
    const slider = new Swiper('.js-itemCarousel', {
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: false,
        draggable: true
      },
      loop: false,
      speed: 600,
      setWrapperSize: false,
      roundLengths: true,
      spaceBetween: '1.9%',
      breakpoints: {
        320: {
          slidesPerView: 1.5,
        },
        375: {
          slidesPerView: 1.5,
        },
        520: {
          slidesPerView: 2.0,
        },
        768: {
          slidesPerView: 4.0,
        },
        1000: {
          slidesPerView: 4.5,
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
    });
  }



}
