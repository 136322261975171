import "slick-carousel/slick/slick.scss";
import "@/css/index";
import 'slick-carousel';
import "swiper/swiper.scss"

import { TweenMax, Power3, ScrollToPlugin } from 'gsap/all'
const plugins = [ ScrollToPlugin ]

import UserAgent from './lib/UserAgent';
import Touch from './lib/Touch';
import BrowserDetector from './lib/BrowserDetector';
import UpdateState from './lib/UpdateState';
import MediaQuery from './lib/MediaQuery';
const mediaQuery = new MediaQuery([
  {
    type: 'MOBILE',
    media: '(max-width:640px)'
  },
  {
    type: 'TABLET',
    media: '(min-width:641px) and (max-width:1023px)'
  },
  {
    type: 'DESKTOP',
    media: '(min-width:1024px) and (max-width:1539px)'
  },
  {
    type: 'DESKTOP_L',
    media: '(min-width:1540px) and (max-width:1779px)'
  },
  {
    type: 'DESKTOP_LL',
    media: '(min-width:1780px)'
  }
]);

var state = {
  WIN_WIDTH: $(window).width(),
  WIN_HEIGHT: $(window).height(),
  WIN_TOP: $(window).scrollTop(),
  IS_TOUCH: 'ontouchstart' in window
};

state.UA    = new UserAgent()._ua;
state.TOUCH = new Touch()._touch;

new UpdateState(state);

// import Viewport from './modules/Viewport';
// const viewport = new Viewport(state, 1280);

import Anchor from './modules/Anchor';
import Clamp from './modules/Clamp';
import Linkage from './modules/Linkage';
import EqualHeight from './modules/EqualHeight';
import EventResizeEnd from './modules/EventResizeEnd';
import Share from './modules/Share';
import Core from './Core';
import GlobalMenu from './GlobalMenu';
import HomeHeroSlider from './HomeHeroSlider';
import ItemCarousel from './ItemCarousel';
import Snake from './Snake';
import MovieModal from './MovieModal';
import ItemGallery from './ItemGallery';

function init() {
  new BrowserDetector(state);
  new Anchor(state);
  new Clamp(state);
  new Linkage(state);
  new EqualHeight(state);
  new EventResizeEnd(state);
  new Share(state);
  new Core(state);
  new GlobalMenu(state);
  new HomeHeroSlider(state);
  new ItemCarousel(state);
  new Snake(state);
  new MovieModal(state);
  new ItemGallery(state);

  // viewport.resize(state, 1280);

  $(window).trigger('resize');
  $(window).trigger('resizeEnd');
  mediaQuery.dispatchMatch();
}

// document.oncontextmenu = () => { return false; }
$(document).ready(() => {
  init();
});
