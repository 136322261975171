import Core from './Core';



export default class GlobalMenu {
  constructor(state) {
    this._state = state;
    this._trigger();
    this._accordion();
  }



  _accordion() {

  }



  _trigger() {
    const $tigger = $('.js-trigger');

    $tigger.on(this._state.TOUCH.CLICK, (e) => {
      e.preventDefault();
      this._switchMenu();
      return false;
    });
  }



  _switchMenu() {
    var _class = 'show-menu';
    var is_open = $('body').hasClass(_class);

    if (is_open) {
      $('body').removeClass(_class);
    }
    else {
      $('body').addClass(_class);
    }
  }



}
